:root{
  --btnBGCol: rgba(89, 61, 252, 0.103);
  --btnTextCol: rgb(182, 182, 182);
  --mPlayerHpos: 0%;
  --tutPlayerHpos: 0%;
  /* --vitaHpos: 0%; */
  /* --sec000Hpos: 0%;
  --sec001Hpos: 0%;
  --sec002Hpos: 0%;
  --sec003Hpos: 0%;
  --sec004Hpos: 0%;
  --sec005Hpos: 0%;
  --sec006Hpos: 0%;
  --sec007Hpos: 0%;
  --sec008Hpos: 0%;
  --sec009Hpos: 0%;
  --sec010Hpos: 0%; */
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100vw;
  height: 100vh;
}


body {
  background: #000000;
  font-family: 'Barlow Condensed', sans-serif;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}


::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(26, 26, 26);        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(134, 134, 134);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(26,26,26);  /* creates padding around scroll thumb */

}


#container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

/*###################VITA PDF VIEW##############*/

.pdfView {
  display: none;
  /* visibility: hidden; */
  position: absolute;
  opacity: 0.85;
  width: 60%;
  height: 60%;
  margin-top: 5%;
  margin-left: 20%;
  /* margin-left: auto; */
  /* top: -50rem; */
  /* top: 5%; */
  /* left: -0%; */
  z-index: 5;
}

/* ##### jpg SLIDESHOW############# */

.mySlides {display: none}
.mySlides .fade img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Caption text */
.vitaLink {
  /* border: 1px solid white;
  border-radius: 3px 0 0 3px; */
  color: #f2f2f2;
  position: absolute;
}

.vl01_1 {
  width: 12%;
  height:  4%;
  right: 5%;
  bottom: 7%;
}

.vl02_1 {
  width: 12%;
  height:  5%;
  right: 5%;
  bottom: 42%;
}

.vl05_1 {
  width: 12%;
  height:  2%;
  right: 22%;
  bottom: 80%;
}
.vl05_2 {
  width: 25%;
  height:  2%;
  right: 22%;
  bottom: 57%;
}
.vl05_3 {
  width: 25%;
  height:  2%;
  right: 22%;
  bottom: 49%;
}
.vl05_4 {
  width: 20%;
  height:  2%;
  right: 22%;
  bottom: 36%;
}
.vl05_5 {
  width: 25%;
  height:  2%;
  right: 22%;
  bottom: 30.5%;
}
.vl05_6 {
  width: 28%;
  height:  2%;
  right: 22%;
  bottom: 25%;
}
.vl05_7 {
  width: 25%;
  height:  2%;
  right: 22%;
  bottom: 17%;
}


.vl06_1 {
  width: 24%;
  height:  2%;
  right: 22%;
  bottom: 72%;
}
.vl06_2 {
  width: 10%;
  height:  2%;
  right: 22%;
  bottom: 65%;
}
.vl06_3 {
  width: 17%;
  height:  2%;
  right: 22%;
  bottom: 57.5%;
}
.vl06_4 {
  width: 28%;
  height:  2%;
  right: 22%;
  bottom: 52%;
}
.vl06_5 {
  width: 17%;
  height:  2%;
  right: 22%;
  bottom: 44.5%;
}
.vl06_6 {
  width: 10%;
  height:  2%;
  right: 22%;
  bottom: 40.8%;
}
.vl06_7 {
  width: 10%;
  height:  2%;
  right: 22%;
  bottom: 33.5%;
}
.vl06_8 {
  width: 18%;
  height:  2%;
  right: 22%;
  bottom: 20.5%;
}
.vl06_9 {
  width: 20%;
  height:  2%;
  right: 22%;
  bottom: 15%;
}
.vl06_10 {
  width: 16%;
  height:  2%;
  right: 22%;
  bottom: 11.5%;
}

.vl09_1 {
  width: 12%;
  height:  2%;
  right: 15%;
  bottom: 49%;
}
.vl09_2 {
  width: 6%;
  height:  2%;
  left: 7%;
  bottom: 32.8%;
}
.vl09_3 {
  width: 10%;
  height:  2%;
  left: 22%;
  bottom: 13%;
}

.vl10_1 {
  width: 16%;
  height:  2%;
  right: 10%;
  bottom: 58.5%;
}
.vl10_2 {
  width: 10%;
  height:  2%;
  left: 30%;
  bottom: 13%;
}

.vl11_1 {
  width: 6%;
  height:  2%;
  left: 13%;
  bottom: 75%;
}
.vl11_2 {
  width: 6%;
  height:  2%;
  right: 43%;
  bottom: 9.5%;
}

.vl12_1 {
  width: 20%;
  height:  2%;
  left: 5%;
  bottom: 39%;
}

.vitaLinkSpan {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}



/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}



/*############ GENERAL ######################*/

.button{
  margin-top: 1rem;
}

/* .button {
  width: 400px;
  background-color: rgba(255, 255, 255, 0.154);
  border: none; */
/* color: rgb(255, 255, 255, 0.164); */
/* color: black;
  padding: 13px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vh;
  margin: 13px 2px;
  cursor: pointer;
} */

.cstmBtn:hover {
  background-color: var(--btnBGCol);
  color:var(--btnTextCol);
}

/*------------------------------------------- SKELETON ------------------------------------------- */

/* General sections */

.section {
  padding: 8rem 0 7rem;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.section-heading,
.section-description {
  margin-bottom: 1.2rem;
}

.section-heading {
  font-weight: 300;
}

.section-description {
  font-weight: 500;
  line-height: 1.2;
}



/* seperate sections */

/*SECTION 00 HOME //////////////////////////////////*/
.section00 {
  /* padding-bottom: 5rem; */
  color: rgb(0, 0, 0);
  opacity: 0.7;
}

.section00 .section-heading {
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section00 .section-description {
  position: relative;
  max-width: 50%;
  margin: 55rem auto 3rem;
  text-shadow: -1px -1px 8px #888888;
}


/*SECTION 01 EYES OUT//////////////////////////////////*/
.section01 {
  color: black;
  opacity: 0.0;
}

.section01 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 2rem;
  text-shadow: -1px 1px 13px #c5c5c5;
}

.section01 .section-description {
  position: relative;
  max-width: 100%;
  margin: 40rem auto 3rem;
  text-shadow: 1px -1px 8px #888888;
}




/*SECTION 02 GALLERY //////////////////////////////////*/
.section02 {
  color: black;
  opacity: 0.0;
}

.section02 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 48rem;
  text-shadow: -1px -1px 13px #c5c5c5;
}

.section02 .section-description {
  position: relative;
  max-width: 100%;
  margin: 0rem auto 3rem;
  text-shadow: 1px -1px 8px #888888;
}

/*SECTION 03 AUDIO VISUALIZER //////////////////////////////////*/
.section03 {
  color: black;
  opacity: 0.0;
}

.section03 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 5rem;
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section03 .section-description {
  position: relative;
  max-width: 100%;
  margin: 45rem auto 3rem;
  text-shadow: 1px -1px 8px #888888;
}

/*SECTION 04 Sound Archive //////////////////////////////////*/
.section04 {
  color: black;
  opacity: 0.0;
}

.section04 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 7rem;
   text-shadow: 0px 1px 13px #c5c5c5;
}

.section04 .section-description {
  position: relative;
  max-width: 80%;
  margin: 55rem auto 3rem;
  text-shadow: 0px -1px 8px #888888;
}

/*SECTION 05 MARKETPLACE //////////////////////////////////*/
.section05 {
  color: black;
  opacity: 0.0;
}

.section05 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 7rem;
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section05 .section-description {
  position: relative;
  max-width: 80%;
  margin: 55rem auto 3rem;
  text-shadow: 0px -1px 8px #888888;
}


/*SECTION 06 TUTORIALS//////////////////////////////////*/
.section06 {
  color: black;
  opacity: 0.0;
  z-index: 9;
}

.section06 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 7rem;
  text-shadow: -1px 1px 13px #c5c5c5;
}

.section06 .section-description {
  position: relative;
  max-width: 90%;
  margin: 2rem 5rem 2rem;
  text-shadow: -1px -1px 8px #888888;
}

/*SECTION 07 LbF //////////////////////////////////*/
.section07 {
  color: black;
  opacity: 0.0;
}

.section07 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin-top: 10rem;
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section07 .section-description {
  position: relative;
  /* max-width: 50%; */
  /* margin: 2rem 4rem 3rem; */
  margin-top: 5rem;
  text-shadow: 1px 1px 8px #888888;
}

/*SECTION 08 VITA //////////////////////////////////*/
.section08 {
  color: black;
  opacity: 0.0;
}

.section08 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  margin: 75rem auto 0rem;
  text-shadow: 1px -1px 13px #c5c5c5;
}

.section08 .section-description {
  position: relative;
  /* max-width: 50%; */
  /* margin: 2rem 4rem 3rem; */
  margin-top: 0rem;
  text-shadow: 1px -1px 8px #888888;
}

/*SECTION 09 CONTACT //////////////////////////////////*/
.section09 {
  color: black;
  opacity: 0.0;
}

.section09 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  /* margin: 72rem auto 0rem; */
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section09 .section-description {
  position: relative;
  /* max-width: 50%; */
  /* margin: 2rem 4rem 3rem; */
  margin-top: 65rem;
  text-shadow: 1px -1px 8px #888888;
}

.section09 a {
  color: rgba(255, 255, 255, 0.154);
}

/*SECTION 10 IMPRINT //////////////////////////////////*/
.section10 {
  color: black;
  opacity: 0.0;
}

.section10 .section-heading {
  position: relative;
  /* margin: 2rem auto 3rem; */
  /* margin: 72rem auto 0rem; */
  text-shadow: 1px 1px 13px #c5c5c5;
}

.section10 .section-description {
  position: relative;
  /* max-width: 50%; */
  /* margin: 2rem 4rem 3rem; */
  margin-top: 20rem;
  text-shadow: 1px -1px 8px #888888;
}

/*##################################*/

/* .section h2 {
  width: 100%;
  display: block;
  font-size: 3vw;
  line-height: 1.1 text-transform: uppercase;
} */

.svg-map {
  display: none;
  visibility: hidden;
}

/* just for the statistics GUI */
#modal_4-content canvas {
  float: left;
  padding-right: 100px;

}

.progress-bar-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#progress-bar {
  width: 30%;
  margin-top: 0.5%;
  height: 2%;
}

label {
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2.5rem;
}

/*##################### music player ##########################*/

.mplayerContainer {
  display: none;
  opacity: .85;
  width: 50%;
  height: 55%;
  overflow-y: scroll;
  position: absolute;
  left: 25%;
  /* top: 490%; */
  top: var(--mplayerHpos);
  background: var(--overlayBG);
}

.mplayerContainer .mplayerHeading {
  font-size: 3rem;
  padding: 2rem 0;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif, Tahoma, sans-serif;
  text-shadow: 0 .5 1rem black;
  color: #fff;
}

.mplayerContainer .music-container {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.mplayerContainer .music-container .mplayerBox {
  height: 95%;
  width: 90%;
  display: flex;
  align-items: center;
  background: #000000;
  border-radius: 1rem;
  box-shadow: 0 .5 1rem black;
  overflow: hidden;
  margin: 2rem;
}

.mplayerContainer .music-container .mplayerBox .mplayerImage {
  height: 100%;
  width: 25%;
  opacity: .85;
}

.mplayerContainer .music-container .mplayerBox .mplayerImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic {
  width: 75%;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic h4 {
  color: #ccc;
  font-weight: 400;
  font-size: 1.8rem;
  margin-left: 2%;
  margin-right: 2%;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio {
  width: 100%;
  outline: none;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-enclosure {
  background: none;
  border-radius: 1px;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-panel {
  background-color: #1b1b1b;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-play-button {
  /* -webkit-filter: invert(100%); */
  filter: invert(100%);

}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-play-button:hover {
  background-color: rgba(16, 20, 20, 0.7);
}
.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-current-time-display {
  color: #fff;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-time-remaining-display {
  color: #fff;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-timeline {
  /* -webkit-filter: invert(100%); */
  filter: invert(100%);
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-mute-button {
  /* -webkit-filter: invert(100%);; */
  filter: invert(100%);;
}

.mplayerContainer .music-container .mplayerBox .mplayerMusic audio::-webkit-media-controls-volume-slider {
  /* -webkit-filter: invert(100%); */
  filter: invert(100%);
}


/*@media (max-width:600px) {

}*/

/*##################### tutorial player ##########################*/

.tutorialContainer {
  display: none;
  /* opacity: 0.85; */
  position: absolute;
  width: 45%;
  height: 73%;
  /* right: 0%; */
  left: 0%;
  /* height: 50px; */
  /* top: var(--tutorialsVert); */
  /* grid-template-columns: 3fr 1fr; */
  grid-template-columns: 1fr;
  gap: 15px;
  align-items: flex-start;
  padding: 0px 5%;
  overflow-y: hidden;
  /* top: 740%; */
  top: var(--tutPlayerHpos);
  z-index: 15;
}

.tutorialContainer .tutorialMainVid {
  background: var(--overlayBG);
  border-radius: 5px;
}

/**/
.tutorialContainer .tutorialMainVid video {
  width: 100%;
  border-radius: 5px;
}

.tutorialContainer .tutorialMainVid .tutorialTitle {
  color: rgb(158, 158, 158);
  font-weight: 400;
  font-size: 2.0rem;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

/**/

.tutorialContainer .tutorialVideo-list {
  background: var(--overlayBG);
  border-radius: 5px;
  height: 100%;
  overflow-y: scroll;
}

.tutorialContainer .tutorialVideo-list::-webkit-scrollbar {
  width: 7px;
}

.tutorialContainer .tutorialVideo-list::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 50px;
}

.tutorialContainer .tutorialVideo-list::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 50px;
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid video {
  width: 100px;
  border-radius: 5px;
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid {
  display: flex;
  align-items: center;
  gap: 15px;
  background: #24242466;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid:hover {
  background: rgba(119, 119, 119, 0.438);
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid.active {
  background: #205f8a66;
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid.active .tutorialTitle {
  color: #fff;
}

.tutorialContainer .tutorialVideo-list .tutorialSideVid .tutorialTitle {
  color: rgb(177, 177, 177);
  font-weight: 400;
  font-size: 18px;
}

/*Impressum*/
#imprint {
  margin-right: 40px;
  color:#fff;
}

/* NOTICE OVERLAY */

#messageOverlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
}
#messageText{
  position: absolute;
  top: 45%;
  left: 50%;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  color: red;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

/* NAVIGATION BAR*/

.nav-bar {
  position: fixed;
  right: 0;
  z-index: 10000;
}
.nav-bar ul {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-bar ul h3{
  font-size:1.5vw;
}
.nav-bar ul li {
  align-self: flex-end;
  list-style: none;
  text-align: center;
  /*background-size: 100% 100%;
  background-repeat: no-repeat;*/
  margin-top: 1rem;
  margin-right: 2rem;
  /*padding: 7px 20px;*/
  color: #000;
  text-shadow: 1px 1px 13px #c5c5c5;
}
.nav-bar ul li:hover, .nav-bar ul li.active  {
  cursor: pointer;
  color: #ffffff50;
  /*background: red;*/
}
.nav-bar ul li {
  background: none;
  /*filter: drop-shadow(-5px -5px 5px #3f0a3a);*/
}


/*/////////////////////////////////////////////////////////////////////*/
/* Mobile first queries */


/* SMALL */
/* @media only screen and (min-width: 320px) and (max-width: 479px) { */
@media only screen and (max-width: 467px) {

  #messageText{
    top: 40%;
    left: 50%;
    font-size: 25px;
  } 
  
  .section-heading {
    font-weight: 300;
    font-size: 3.2rem;
  }
  
  .section-description {
    font-weight: 500;
    line-height: 1.2;
  }

  .section00 .section-heading {
    margin-top: -1rem;
  }
  .section00 .section-description {
    margin-top: 20rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .section01 .section-heading {
    margin-top: 2rem;
  }
  .section01 .section-description {
    margin-top: 20rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .section02 .section-heading {
    margin-top: 7rem;
  }
  .section02 .section-description {
    margin-top: 25rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .section03 .section-heading {
    margin-top: 8rem;
  }
  .section03 .section-description {
    margin-top: 29rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .section04 .section-heading {
    margin-top: 10rem;
  }
  .section04 .section-description {
    margin-top: 29rem;
    margin-left: 3.4rem;
    margin-right: 1.5rem;
  }

  /*######################*/

  .mplayerContainer {
      height:53%;
      width: 70%;
      left: 15%;
      top: var(--mplayerHpos);

    }
  .mplayerContainer .music-container .mplayerBox {
    margin:.2; 
    height: 12rem;
    width: 100%;
    flex-flow: column;
  }

  .mplayerContainer .music-container .mplayerBox .mplayerImage {
    height: 50%;
    width: 100%;
  }

  .mplayerContainer .music-container .mplayerBox .mplayerMusic {
    height: 30%;
    width: 100%;
  }
  .mplayerContainer .music-container .mplayerBox .mplayerMusic h4{
    height: 0%;
    width: 100%;
  }

  /*######################*/

  .section05 .section-heading {
    margin-top: 13rem;
  }
  .section05 .section-description {
    margin-top: 23rem;
    margin-left: 2.5rem;
    margin-right: 1.5rem;
  }

/*Tutorials*/
  .section06 .section-heading {
    margin-top: 15rem;
  }
  .section06 .section-description {
    margin-top: 1rem;
    margin-left: 2.5rem;
    margin-right: 1.5rem;
  }

/*########################*/

  .tutorialContainer {
    width: 80%;
    height: 45%;
    margin: 0px;
    /* right: 0%; */
    left: 5%;
    /* top:783%; */
    top: calc(var(--tutPlayerHpos) +0px);
    gap: 0px;
    /* z-index: 12; */
  }
  .tutorialMainVid .tutorialVideo .tutorialTitle {
    font-size: 4vh;
  }
  .tutorialContainer .tutorialVideo-list .tutorialSideVid .tutorialTitle {
    font-size: 2.2vh;
  }

/*########################*/

  .section07 .section-heading {
    margin-top: 18rem;
  }
  .section07 .section-description {
    margin-top: 0rem;
    margin-left: 2.5rem;
    margin-right: 1.5rem;
  }

  .section07 .section-description button{
    margin-top: 10rem;
  }

  .section08 .section-heading {
    margin-top: 23rem;
  }
  .section08 button{
    margin-top: .5rem;
  }

  .pdfView {
    width: 90%;
    height: 10%;
    margin-top: 110%;
    margin-left: 5%;
    z-index: 10;
  }

  .section09 .section-description {
    margin-top: 50rem;
    margin-left: 2.5rem;
    margin-right: 1.5rem;
  }

  .section10 {
    width: 100%;
    margin-top: 15rem;
    margin-left: 8%;
  }
  .section10 .section-description{
    margin-top: 50rem;
  }
}



/* medium */
@media only screen and (min-width: 468px) and (max-width: 1007px) {

  #messageText{
    top: 40%;
    left: 50%;
    font-size: 25px;
  }  

  .section00 .section-heading {
    margin-top: 3rem;
    margin-left: 3rem;
  }

  .section00 .section-description {
    margin-top: 58rem;
    margin-left: 8rem;
  }

  .section01 .section-description {
    margin-top: 38rem;
    margin-left: 3rem;
    margin-right: 1.5rem;
  }

  .section02 .section-heading {
    margin-top: 50rem;
    margin-left: 3rem;
  }
  .section02 .section-description {
    margin-top: 5rem;
    margin-left: 2.9rem;
  }

  .section03 .section-description {
    margin-top: 55rem;
    margin-left: 2.9rem;
  }

  .section04 .section-description {
    margin-top: 65rem;

  }

  /*######################*/

  .mplayerContainer {
    height:50%;
    width: 70%;
    left: 15%;
    top: var(--mplayerHpos);

  }
  .mplayerContainer .music-container .mplayerBox {
    margin:.2; 
    height: 20rem;
    width: 85%;
    flex-flow: column;
  }

  .mplayerContainer .music-container .mplayerBox .mplayerImage {
    height: 50%;
    width: 50%;
  }

  .mplayerContainer .music-container .mplayerBox .mplayerMusic {
    height: 30%;
    width: 100%;
  }
  .mplayerContainer .music-container .mplayerBox .mplayerMusic h4{
    margin-top: 1rem;
    height: 0%;
    width: 100%;
  }

/*######################*/

.section05 .section-heading {
  margin-top: 15rem;
  margin-left: 3rem;
}
.section05 .section-description {
  margin-top: 55rem;
  margin-left: 14rem;
}

.section06 .section-heading {
  margin-top: 12rem;
}
.section06 .section-description {
  margin-top: 45rem;
  margin-left: 4rem;
}

/*########################*/

  .tutorialContainer {
    width: 80%;
    height: 55%;
    /* right: 0%; */
    left: 5%;
    top: calc(var(--tutPlayerHpos) - 15px);
    gap: 0px;
  }
  .tutorialMainVid .tutorialVideo .tutorialTitle {
    font-size: 3vh;
  }
  .tutorialContainer .tutorialVideo-list .tutorialSideVid .tutorialTitle {
    font-size: 1.8vh;
  }

/*########################*/

  .section07 .section-heading {
    margin-top: 15rem;
  }

  .section07 .section-description {
    margin-left: 4rem;
  }
  .section07 .section-description button{
    margin-top: 30rem;
  }

  .section08 .section-heading {
    margin-top: 80rem;
  }

  .pdfView {
    width: 90%;
    height: 10%;
    margin-top: 15%;
    margin-left: 5%;
  }

  .section09 .section-description {
    margin-top: 75rem;
  }

  .section10 {
    width: 100%;
    margin-left: 0%;
  }
  .section10 .section-description{
    margin-top: 55rem;
  }


}

/*/////////////////////////////////////////////////////////////////////*/

/* Large */
@media only screen and (min-width: 1008px) and (orientation: portrait){

  .cstmBtn {
    width: 25rem;
    height: 8rem;
    margin-top: 5rem;
    font-size: 2.5rem;
    border: 2px solid white;
    border-radius: 1px;
  }

  .section00 .section-heading {
    font-size: 7.5rem;
    margin-top: 5rem;
    margin-left: 4rem;
  }
  .section00 .section-description {
    font-size: 3.4rem;
    margin: 135rem 0 0 10rem;
  }

  .section01 .section-heading {
    font-size: 7.5rem;
    margin-top: 8rem;
    margin-right: 7rem;
    
  }
  .section01 .section-description {
    font-size: 3.4rem;
    margin: 90rem 0 0 10rem;
  }

  .section02 .section-heading {
    font-size: 7.5rem;
    margin-top: 8rem;
    margin-right: 7rem;
    
  }
  .section02 .section-description {
    font-size: 3.4rem;
    margin: 100rem 0 0 10rem;
  }

  .section03 .section-heading {
    font-size: 7.5rem;
    margin-top: 8rem;
    margin-left: 7rem;
    
  }
  .section03 .section-description {
    font-size: 3.4rem;
    margin: 100rem 0 0 10rem;
  }

  .section04 .section-heading {
    font-size: 7.5rem;
    margin-top: 15rem;
    
  }
  .section04 .section-description {
    font-size: 3.4rem;
    margin-top: 110rem;
  }
  .section04 .section-description .cstmBtn{
    width: 35rem;
  }

  /*######################*/

  .mplayerContainer {
    height:53%;
    width: 80%;
    left: 10%;
    top: 450%;

  }
  .mplayerContainer .music-container .mplayerBox {
    margin:.2; 
    height: 25rem;
    width: 100%;
    flex-flow: column;
  }

  .mplayerContainer .music-container .mplayerBox .mplayerImage {
    height: 50%;
    width: 80%;
  }
  /*here is where you can adjust the width of the player after you scaled the "audio property"*/
  .mplayerContainer .music-container .mplayerBox .mplayerMusic {
    height: 80%;
    width: 55%;
  }
  .mplayerContainer .music-container .mplayerBox .mplayerMusic h4{
    font-size: 3rem;
    height: 20%;
    width: 96%;
  }
  .mplayerContainer .music-container .mplayerBox .mplayerMusic audio {
    transform: scale(1.75);
  }

/*######################*/

  .section05 .section-heading {
    font-size: 7.5rem;
    margin-top: 15rem;
    margin-left: 5rem;
    
  }
  .section05 .section-description {
    font-size: 3.4rem;
    margin-top: 110rem;
  }

  .section06 .section-heading {
    font-size: 7.5rem;
    margin-top: 15rem;
    
  }
  .section06 .section-description {
    font-size: 3.4rem;
    margin-top: 110rem;
  }
  .section06 .section-description .cstmBtn{
    width: 30rem;
  }

  /*########################*/

  .tutorialContainer {
    width: 80%;
    height: 55%;
    /* right: 0%; */
    left: 5%;
    top: calc(var(--tutPlayerHpos) - 100px);
    gap: 0px;
  }
  .tutorialMainVid .tutorialVideo .tutorialTitle {
    font-size: 3vh;
  }
  .tutorialContainer .tutorialVideo-list .tutorialSideVid .tutorialTitle {
    font-size: 1.8vh;
  }

/*########################*/

  .section07 .section-heading {
    font-size: 7.5rem;
    margin-top: 25rem;
    margin-left: 7rem;
    
  }
  .section07 .section-description {
    font-size: 3.4rem;
    margin-left: 7rem;
  }
  .section07 .section-description button{
    margin-top: 80rem;
  }

  .section08 .section-heading {
    font-size: 7.5rem;
    margin-top: 30rem;
    margin-left: 45rem;
    
  }
  .section08 .section-description {
    margin-top: 105rem;
  }
  .section08 .cstmBtn{
    width: 38rem;
  }

  .pdfView {
    width: 90%;
    height: 10%;
    margin-top: 45%;
    margin-left: 5%;
  }


  .section09 .section-description {
    font-size: 3.4rem;
    margin-left: 15rem;
    margin-top: 140rem;
  }

  .section10 {
    width: 130%;
    margin-left: -30%;
    font-size: 3rem;
    }
  .section10 .section-description{
    margin-top: 100rem;
  }

  .section10 h3{
  font-size: 7rem;
  }
.section10 h4{
  font-size: 5rem;
  }

}

/*/////////////////////////////////////////////////////////////////////*/
